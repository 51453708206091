// theme.js
//shadowing the chakra-ui gatsby theme
import { extendTheme } from "@chakra-ui/react"
import "@fontsource/lexend-deca"

const theme = extendTheme({

  config: {
    initialColorMode: "light",
    useSystemColorMode: true,
  },
  colors: {
    brand: {
      700: "#7035FF",
    },
    red:
    {
      50: '#ffe6f5',
      100: '#f9b9dc',
      200: '#f28bc3',
      300: '#ed5fab',
      400: '#e83594',
      500: '#ce1f7a',
      600: '#a01760',
      700: '#730f44',
      800: '#460629',
      900: '#1a0010',
    },
    gray: {
      50: '#F2F2F3',
      100: '#DBDBDC',
      200: '#C3C3C6',
      300: '#ACACAF',
      400: '#949499',
      500: '#7D7D82',
      600: '#646468',
      700: '#4B4B4E',
      800: '#323234',
      900: '#19191A',
    }
  },
  fonts: {
    body: "Lexend Deca, sans-serif",
    heading: "Lexend Deca, sans-serif",
    mono: "Menlo, monospace",
  },
  radii:{
    //such an oversight on an otherwise great library
    none: "0",
    sm: "0",
    base: "0",
    md: "0",
    lg: "0",
    xl: "0",
    "2xl": "0",
    "3xl": "0",
    full: "0",
  },
  components: {
    Link: {
      baseStyle: {
        textDecoration: "underline",
        _hover: {
          color: "red.500",
        },
      },
    },
  },


})

export default theme